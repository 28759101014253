import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import Footer from '../../blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import ContentColumn from "../../blocks/Content/Column/Column";
import List from "../../blocks/Content/List";
import {Container} from "theme-ui";

const PrivacyPolicy = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)
    return (
        <Layout {...props}>
            <Seo title='Security Policy SGC'/>
            {/* Blocks */}
            <Header content={content['header-light']}/>
            <Divider space='6'/>
            <ContentColumn content={content['content-one']}/>
            <List content={content['list-content-one']}/>
            <Divider space='2'/>
            <ContentColumn content={content['content-two']}/>
            <List content={content['list-content-two']}/>
            <Divider space='2'/>
            <ContentColumn content={content['content-three']}/>
            <List content={content['list-content-three']}/>
            <Divider space='2'/>
            <ContentColumn content={content['content-four']}/>
            <List content={content['list-content-four']}/>
            <Divider space='2'/>
            <ContentColumn content={content['content-five']}/>
            <List content={content['list-content-five']}/>
            <Divider space='2'/>
            <ContentColumn content={content['content-six']}/>
            <Divider space='5'/>
            <Footer content={content['footer-en']}/>
        </Layout>
    )
}


export const query = graphql`
  query innerpageSiteSgcPolicyBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/sgc-policy-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PrivacyPolicy
